<template>
    <div class="wrapper">
        <div class="container-fluid">

            <div class="row">
                <div class="col-sm-12 text-center">

                    <div class="wrapper-page">
                        <img :src="imgGif" alt="" height="120">
                        <h3 class="text-uppercase">Maaf, Website yang Anda Cari Tidak Tersedia</h3>
                        <p class="text-muted mb-2">Saat ini, Anda mengalami masalah teknis. Halaman yang Anda cari tidak
                            dapat ditemukan. Anda dapat kembali ke halaman utama untuk mendapatkan informasi lebih
                            lanjut.</p>

                        <button @click="goToHome" class="btn btn-custom waves-effect waves-light m-t-20"> Kembali Ke
                            Home</button>
                    </div>

                </div>
            </div>
            <!-- end row -->
        </div>
        <!-- End container-fluid -->
        <Footer />
    </div>
</template>

<script>
import Footer from '@/views/layouts/FooterComponent.vue';
import notFoundGif from "@/assets/images/index.gif";
export default {
    name: "NotFound",
    components: {
        Footer
    },
    data() {
        return {
            imgGif: notFoundGif
        }
    },
    methods: {
        goToHome() {
            this.$router.push({ name: "Home" })
        }
    }
}
</script>
<style lang="css" scoped>
</style>